import React from 'react';
import { Container } from 'react-bootstrap';
import Banner from '../Component/Banner';

import NavBar from './NavBar';
import Footer from './Footer';

function checkLightMode() {
    if (sessionStorage.getItem('lightMode') === 'true')  {
      return true
    }
  }

class Apps extends React.Component {


    render () {
        return (
            <>
                <Container className="text-center">
                    <NavBar testProps= { checkLightMode() ? "light" : "dark" } className="navTest"/>
                        <Banner caption="App Projects"/>
                        <div className='Apps'>
                        <h1><a href='/KeikiPartyInfo'>Keiki Party</a></h1>
                        <h3>Privacy Policy:</h3>
                        <p>I do not collect any data from users.</p>
                        </div>
                        <div className='Apps'>
                        <h1><a href='/HauulaBB'>Hau'ula Bright Beginnings Web Version</a></h1>
                        <h1><a href='/QuizTrivias'>Quiz Trivias</a></h1>
                        </div>
                </Container>
                <Footer testProps= { checkLightMode() ? "lightMode" : "darkMode" }/>
            </>
        )
    }
}


export default Apps;