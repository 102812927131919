import React from 'react';

import { Container } from 'react-bootstrap';

//Component
import Banner from '../Component/Banner';
import Review from '../Component/Review';
import { SendReview } from '../Component/SendReview';
import NavBar from './NavBar';
import Footer from './Footer';

//Stylesheet
import '../Stylesheet/Review.css';

const script = document.createElement("script");
script.src = './homeScript.js';

function checkLightMode() {
    if (sessionStorage.getItem('lightMode') === 'true')  {
      return true
    }
  }

class HomePage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight
        }

        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        document.body.appendChild(script);
        window.addEventListener('resize',this.handleResize);
    }

    componentWillUnmount() {
        document.body.removeChild(script);
        document.location.reload();
        window.removeEventListener('resize',this.handleResize)
    }

    handleResize() {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight
            })
    }

    render() {
        return (
            <>
            <Container className="text-center">
            <NavBar testProps= { checkLightMode() ? "light" : "dark" } className="navTest"/>
                    <Banner caption="Welcome"/>
                    <canvas id='javaCanvas' className={checkLightMode() ? "lightMode" : "darkMode"} width={this.state.width/2} height={this.state.height/2}/>
                    <div className="homePage" id="reveal1">
                        <div >
                            <Banner caption="About Me:"/>
                            <p>I couldn't think of anything to put about me. So I've decided to create a review about me lol.</p>
                        </div>
                    </div>
                    <div className="homePage">
                        <div id="reveal2">
                            <h2>Here they are:</h2>
                            <Review />
                        </div>
                        <SendReview />
                    </div>
            </Container>
            <Footer testProps= { checkLightMode() ? "lightMode" : "darkMode" }/>
            </>
        );
    }
};

export default HomePage;