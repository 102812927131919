import React from 'react';

class Banner extends React.Component {
    render(){
        const caption = this.props.caption;
        return (
            <div className="banner">
                <h1>{caption}</h1>
            </div>
        )
    }
}

export default Banner;