import { Button, Container, Form } from 'react-bootstrap';
import Banner from '../../Component/Banner';
import { useNavigate } from 'react-router-dom';

const RREnd = () => {
    const navigate = useNavigate()


    const startQuiz = (e) => {
        e.preventDefault();
        navigate('/QuizTrivias/RusselRica/RRScoreboard')
    }

    return (
        <>
            <Container className="keiki-party RRTriviaBackground">
            <div className='fontBackground'>
                <Banner caption="THANK YOU!"/>
            </div> 
            <div className='text-center'>
                <div className='Apps'>
                <Form onSubmit={startQuiz}>
                    <h1 className='boldText'>Your Score: {sessionStorage.getItem('TriviaScore')}</h1>
                    <br />
                    <div className='row'>
                        <div className='col'>
                        </div>
                        <div className='col align-self-center fontBackground'>
                            <Button type="submit" value="startQuiz" className="btnSizeUp" disabled= {false} variant="info">Go to Scoreboard</Button>
                        </div>
                        <div className='col'>
                        </div>
                    </div>
                </Form>
                </div>
                <h1>Correct answers:</h1>

                
                <div className="buttonToEdit Apps">
                    <h2>How long have they been together?</h2>
                    <br />
                    <Button className='btnSizeUp'>1 year</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>2 year</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>3 year</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>0 year</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>Where did they first meet officially?</h2>
                    <br />
                    <Button className='btnSizeUp'>Mission Home Philippines</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Salt Lake City, Utah</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>Hawaii</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Disney Land</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>Where did they go on their first date?</h2>
                    <br />
                    <Button className='btnSizeUp'>Laie Temple, Hawaii</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Mall of Asia, Philippines</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Mission Home</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>Park by the Capitol</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>The couple's favorite hobby to do together.</h2>
                    <br />
                    <Button className='btnSizeUp'>Eating</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>Going to the gym</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Hiking</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Snorkeling</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>Couple's favorite food to eat together.</h2>
                    <br />
                    <Button className='btnSizeUp'>Pancit Canton</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Rice</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Veggie veggie veggie</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>Seafood</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>How many kids do they want to have?</h2>
                    <br />
                    <Button className='btnSizeUp'>1-2</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>3-4</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>4-5</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>As the sand which is upon the sea</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>Where did they have their first kiss?</h2>
                    <br />
                    <Button className='btnSizeUp'>The Delicate Arch</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>A spot close to the Laie temple</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>A spot close to the Bountiful temple</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Foodland, Laie</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>Where are they going for their honeymoon?</h2>
                    <br />
                    <Button className='btnSizeUp'>Laoag, Philippines</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>Orlando, Florida</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Los Angeles, California</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Tokyo, Japan</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>What's their favorite restaurant?</h2>
                    <br />
                    <Button className='btnSizeUp'>Ombu Korean BBQ</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Jollibee</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>Sushi Buritto</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Whole Foods</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>Where will they live after getting married?</h2>
                    <br />
                    <Button className='btnSizeUp'>Philippines</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Hawaii</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>New York</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>Utah</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>Where is Rica's dream vacation?</h2>
                    <br />
                    <Button className='btnSizeUp'>Austria</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Scotland</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>Jerusalem</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Japan</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>Where is Russel's dream vacation?</h2>
                    <br />
                    <Button className='btnSizeUp'>Viti Levu, Fiji</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Malé Atoll, Maldives</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>Santorini, Greece</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Machu Picchu, Peru</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>How many siblings does Rica have?</h2>
                    <br />
                    <Button className='btnSizeUp'>1</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>2</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>3</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>4</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>How many siblings does Russel have?</h2>
                    <br />
                    <Button className='btnSizeUp'>1</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>2</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>3</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>4</Button>
                    <br />
                    <br />
                </div>
            </div>
            <div className='row'>
                <div className='usedImageStyle'>
                    <a href="http://www.freepik.com">Designed by coolvector / Freepik</a>
                </div>
                    
                </div>
            </Container>
        </>
    )
}

export default RREnd;