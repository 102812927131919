import { Button, Container, Form } from 'react-bootstrap';
import Banner from '../../Component/Banner';
import { useNavigate } from 'react-router-dom';

const YEnd = () => {
    const navigate = useNavigate()


    const startQuiz = (e) => {
        e.preventDefault();
        navigate('/QuizTrivias/Yoonseo/YScoreboard')
    }

    return (
        <>
            <Container className="keiki-party YTriviaBackground">
            <div className='fontBackground'>
                <Banner caption="THANK YOU!"/>
            </div> 
            <div className='text-center'>
                <div className='Apps'>
                <Form onSubmit={startQuiz}>
                    <h1 className='boldText'>Your Score: {sessionStorage.getItem('TriviaScore')}</h1>
                    <br />
                    <div className='row'>
                        <div className='col'>
                        </div>
                        <div className='col align-self-center fontBackground'>
                            <Button type="submit" value="startQuiz" className="btnSizeUp" disabled= {false} variant="info">Go to Scoreboard</Button>
                        </div>
                        <div className='col'>
                        </div>
                    </div>
                </Form>
                </div>
                <h1>Correct answers:</h1>

                
                <div className="buttonToEdit Apps">
                    <h2>When is Yoonseo's birthday?</h2>
                    <br />
                    <Button className='btnSizeUp'>December 8, 2022</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>December 9, 2022</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>December 10, 2022</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>December 11, 2022</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>How many inches was Yoonseo when born?</h2>
                    <br />
                    <Button className='btnSizeUp'>11 inches</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>12 inches</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>13 inches</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>14 inches</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>What was Yoonseo's weight at birth?</h2>
                    <br />
                    <Button className='btnSizeUp'>5lbs and 10oz</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>5lbs and 11oz</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>6lbs</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>6lbs and 1oz</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>What was the exact time Yoonseo was born?</h2>
                    <br />
                    <Button className='btnSizeUp'>12:32 pm</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>12:33 pm</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>12:32 am</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>12:33 am</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>When did Yoonseo first start walking?</h2>
                    <br />
                    <Button className='btnSizeUp'>11 months</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>8 months</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>10 months</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>9 months</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>What was the first food Yoonseo tasted at 6 months old?</h2>
                    <br />
                    <Button className='btnSizeUp'>Banana</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>Peach</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Apple</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Rice</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>What were Yoonseo's first words?</h2>
                    <br />
                    <Button className='btnSizeUp'>Peach</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Dada</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>Mama</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Papa</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>What does "Yoonseo" mean in Korean?</h2>
                    <br />
                    <Button className='btnSizeUp'>Faith and Light</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>Wisdom and Truthful</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Integrity and Dignity</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Strength and Courage</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>What is Yoonseo's Hawaiian name?</h2>
                    <br />
                    <Button className='btnSizeUp'>Mountain</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Flower</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>Lei</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Kamehame Wave</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>What is Yoonseo's favorite food?</h2>
                    <br />
                    <Button className='btnSizeUp'>Rice</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Kimchi</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Poi</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>Pasta</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>How did Yoonseo get delivered?</h2>
                    <br />
                    <Button className='btnSizeUp'>Cesarean Delivery</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Water Delivery</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>Normal Delivery</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Package Delivery</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>How many teeth does Yoonseo have?</h2>
                    <br />
                    <Button className='btnSizeUp'>7</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>5</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>8</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>6</Button>
                </div>
                <div className="buttonToEdit Apps">
                    <h2>What's Yoonseo's favorite show?</h2>
                    <br />
                    <Button className='btnSizeUp'>CocoMelon</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Peppa Pig</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp btn-success'>Bounce Patrol</Button>
                    <br />
                    <br />
                    <Button className='btnSizeUp'>Doraemon</Button>
                </div>
            </div>
            </Container>
        </>
    )
}

export default YEnd;