import { Container } from 'react-bootstrap';
import Banner from '../Component/Banner';

const QuizTrivias = () => {

    return (
            <>
                <Container>
                        <Banner caption="Quiz Trivias"/>
                <Banner caption="Aloha" />
                <div className='text-center'>
                <h4>Here are the list of Trivias available:</h4>
                    <div className='Apps'>
                        <h1><a href='/QuizTrivias/RusselRica'>Russel and Rica's Trivia</a></h1>
                        <h1><a href='/QuizTrivias/Yoonseo'>Yoonseo's Trivia</a></h1>
                    </div>
                </div>
                </Container>
            </>
    )
}

export default QuizTrivias;