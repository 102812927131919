import { Button, Container } from 'react-bootstrap';
import Banner from '../../Component/Banner';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get, ref, set } from 'firebase/database';

import { db } from '../../ReactNative/firebase';

const YTrivia = () => {
    const [timer, setTimer] = useState(10)
    const [question, setQuestion] = useState(0)
    const navigate = useNavigate()
    const [chosen0, setChosen0] = useState('ybtnSizeUp')
    const [chosen1, setChosen1] = useState('ybtnSizeUp')
    const [chosen2, setChosen2] = useState('ybtnSizeUp')
    const [chosen3, setChosen3] = useState('ybtnSizeUp')
    const [userAnswers, setUserAnswers] = useState(
        [
            {
                id: 0,
                answer: ''
            },
            {
                id: 1,
                answer: ''
            },
            {
                id: 2,
                answer: ''
            },
            {
                id: 3,
                answer: ''
            },
            {
                id: 4,
                answer: ''
            },
            {
                id: 5,
                answer: ''
            },
            {
                id: 6,
                answer: ''
            },
            {
                id: 7,
                answer: ''
            },
            {
                id: 8,
                answer: ''
            },
            {
                id: 9,
                answer: ''
            },
            {
                id: 10,
                answer: ''
            },
            {
                id: 11,
                answer: ''
            },
            {
                id: 12,
                answer: ''
            },
        ]
    )

    const answerArray = useMemo(() => [
        'December 9, 2022',
        '13 inches',
        '6lbs and 1oz',
        '12:33 pm',
        '9 months old',
        'Peach',
        'Mama',
        'Wisdom and Truthful',
        'Lei',
        'Pasta',
        'Normal Delivery',
        '8',
        'Bounce Patrol',
    ], [])
    
    var questionArray = [
        "When is Yoonseo's birthday?",
        'How many inches was Yoonseo when born?',
        "What was Yoonseo's weight at birth?",
        "What was the exact time Yoonseo was born?",
        "When did Yoonseo first start walking?",
        "What was the first food Yoonseo tasted at 6 months old?",
        "What were Yoonseo's first words?",
        'What does "Yoonseo" mean in Korean?',
        "What is Yoonseo's Hawaiian name?",
        "What is Yoonseo's favorite food?",
        "How did Yoonseo get delivered?",
        "How many teeth does Yoonseo have?",
        "What Yoonseo's favorite show?",
    ]

    var choicesArray = [
        [
            'December 8, 2022',
            'December 9, 2022',
            'December 10, 2022',
            'December 11, 2022',
        ],
        [
            '11 inches',
            '12 inches',
            '13 inches',
            '14 inches',
        ],
        [
            '5lbs and 10oz',
            '5lbs and 11oz',
            '6lbs',
            '6lbs and 1oz',
        ],
        [
            '12:32 pm',
            '12:33 pm',
            '12:32 am',
            '12:33 am',
        ],
        [
            '11 months old',
            '8 months old',
            '10 months old',
            '9 months old',
        ],
        [
            'Banana',
            'Peach',
            'Apple',
            'Rice',
        ],
        [
            'Peach',
            'Dada',
            'Mama',
            'Papa',
        ],
        [
            'Faith and Light',
            'Wisdom and Truthful',
            'Integrity and Dignity',
            'Strength and Courage',
        ],
        [
            'Mountain',
            'Flower',
            'Lei',
            'Kamehame Wave',
        ],
        [
            'Rice',
            'Kimchi',
            'Poi',
            'Pasta',
        ],
        [
            'Cesarean Delivery',
            'Water Delivery',
            'Normal Delivery',
            'Package Deliver',
        ],
        [
            '7',
            '5',
            '8',
            '6',
        ],
        
        [
            'CocoMelon',
            'Peppa Pig',
            'Bounce Patrol',
            'Doraemon',
        ],
    ]


    useEffect(() => {
        if (question === 0) {
            window.scrollTo(0, 0)
        }
        if (sessionStorage.getItem('TriviaScore') ){
            alert("Seems like you've already taken the quiz. Thanks for your participation!")
            navigate('/QuizTrivias/Yoonseo/YScoreboard')
        } else if (!sessionStorage.getItem('TriviaEmail')) {
            alert("Seems like you haven't added your info. Please do so first.")
            navigate('/QuizTrivias/Yoonseo')
        }
        if (question === questionArray.length - 1  && timer === 0) {
            var userScore = 0
            for (var i = 0; i < answerArray.length; i++) {
                if(answerArray[i] === userAnswers[i]) {
                    userScore += 1
                }
            }
            sessionStorage.setItem('TriviaScore', userScore)

            get(ref(db, 'YTriviaScoreboard/')).then((snap) => {
                const theNewValue = []
                for (var i = 0; i < snap.size; i++) {
                    theNewValue.push(snap.val()[i])
                }
                theNewValue.push({
                    email: sessionStorage.getItem('TriviaEmail'),
                    name: sessionStorage.getItem('TriviaName'),
                    score: userScore,
                })
                set(ref(db,'YTriviaScoreboard/'), theNewValue)
            })

            navigate('/QuizTrivias/Yoonseo/YEnd')
        } else {
            if (timer === 0) {
                setTimer(10)
                setQuestion((t) => t + 1)
                setChosen0("ybtnSizeUp")
                setChosen1("ybtnSizeUp")
                setChosen2("ybtnSizeUp")
                setChosen3("ybtnSizeUp")
            } else {
                const interval = setInterval(() => {
                    setTimer((t) => t - 1);
                }, 1000);
                return () => clearInterval(interval)
            }
        }
        const logAnswerArray = () => {
            console.log(answerArray)
        }
        logAnswerArray();
    }, [timer, navigate, question, questionArray.length, userAnswers, answerArray]) // gotta have the timer to manipulate useEffect

    function showChoices() {
        function answerChosen0() {
            // console.log(choicesArray[question][0])
            // userAnswers[question] = choicesArray[question][0]
            // setUserAnswers([
            //     ...userAnswers,
            //     {id: question, answer: choicesArray[question][0]}
            // ])
            const answer = userAnswers.map((a, i) => {
                if (i === question) {
                    return choicesArray[question][0]
                } else {
                    return a
                }
            })
            setUserAnswers(answer)
            setChosen0("ybtnSizeUp ybtn-success")
            setChosen1("ybtnSizeUp")
            setChosen2("ybtnSizeUp")
            setChosen3("ybtnSizeUp")
        }
        function answerChosen1() {
            // console.log(choicesArray[question][1])
            // userAnswers[question] = choicesArray[question][1]
            // console.log(userAnswers)
            const answer = userAnswers.map((a, i) => {
                if (i === question) {
                    return choicesArray[question][1]
                } else {
                    return a
                }
            })
            setUserAnswers(answer)
            setChosen0("ybtnSizeUp")
            setChosen1("ybtnSizeUp ybtn-success")
            setChosen2("ybtnSizeUp")
            setChosen3("ybtnSizeUp")
        }
        function answerChosen2() {
            // console.log(choicesArray[question][2])
            // userAnswers[question] = choicesArray[question][2]
            // console.log(userAnswers)
            const answer = userAnswers.map((a, i) => {
                if (i === question) {
                    return choicesArray[question][2]
                } else {
                    return a
                }
            })
            setUserAnswers(answer)
            setChosen0("ybtnSizeUp")
            setChosen1("ybtnSizeUp")
            setChosen2("ybtnSizeUp ybtn-success")
            setChosen3("ybtnSizeUp")
        }
        function answerChosen3() {
            // console.log(choicesArray[question][3])
            // userAnswers[question] = choicesArray[question][3]
            // console.log(userAnswers)
            const answer = userAnswers.map((a, i) => {
                if (i === question) {
                    return choicesArray[question][3]
                } else {
                    return a
                }
            })
            setUserAnswers(answer)
            setChosen0("ybtnSizeUp")
            setChosen1("ybtnSizeUp")
            setChosen2("ybtnSizeUp")
            setChosen3("ybtnSizeUp ybtn-success")
        }
        return(
            <div className="buttonToEdit">
                <br />
                <Button onClick={answerChosen0} className={chosen0}>{choicesArray[question][0]}</Button>
                <br />
                <br />
                <Button onClick={answerChosen1} className={chosen1}>{choicesArray[question][1]}</Button>
                <br />
                <br />
                <Button onClick={answerChosen2} className={chosen2}>{choicesArray[question][2]}</Button>
                <br />
                <br />
                <Button onClick={answerChosen3} className={chosen3}>{choicesArray[question][3]}</Button>
            </div>
        )
    }

    return (
        <>
            <Container className="keiki-party YTriviaBackground">

                <Banner caption=""/>
            <div className='text-center'>
                <div className='Apps'>
                    <br />
                    <h1 className='Trivia-Timer'>{timer}</h1>
                    <br />
                    <h2 className='fontBackground'>{questionArray[question]}</h2>
                    {showChoices(question)}
                    
                </div>
            </div>
            </Container>
        </>
    )
}

export default YTrivia;