import React, { useState } from 'react';

export default function ResponsiveCanvas() {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [count, setCount] = useState(0);

    window.onresize = handleResize;

    function handleResize() {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
    }

    return (
        <div>
            <p>You clicked {count} times</p>
            <button onClick={() => setCount(count + 1)}>
                Click me
            </button>
            <p>Width: {width} and Height: {height}</p>
        </div>
    )
}