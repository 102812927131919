import React, { useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import Banner from '../Component/Banner';
import Button from 'react-bootstrap/Button';

const KPChangePassword = () => {
    const [password, setPassword] = useInput ({type: 'text'})
    const [uid, setUid] = useInput({ type: 'text' })

    function useInput({ type }) {
        const [value, setValue] = useState("");
        const input = <input value={value} onChange={e => setValue(e.target.value)} type={type} />;
        return [value, input];
    }

    function changePasswordFunc() {
        fetch('http://localhost:8000/ChangePassword', {
            method: 'POST',
            headers: { // seems like without this. it won't read the request to the server
                "Content-type": "application/json; charset=UTF-8"
            },
            body: JSON.stringify({
                "uid": uid,
                "password": password,
                
            })
        }).then(function(response) {
            // console.log(response)
            return response.json()
        })
        alert("Done sending request to the server.")
    }

    return (
            <>
                <Container className="keiki-party">
                        <Banner caption="KEIKI PARTY"/>
                <Banner caption="CHANGE PASSWORD" />
                <div className='text-center'>
                    <div>
                        <Form>
                        <div>
                            <Form.Label>UID (FOR CHANGING EMAIL):</Form.Label>
                                {setUid}
                        </div>
                        <div>
                            <Form.Label>Change Password to:</Form.Label>
                            {setPassword}
                        </div>
                        </Form>
                        <div><p></p></div>
                    </div>
                    <div><p></p></div>
                    <div>
                        <Button type='submit' onClick={changePasswordFunc}>Change Password</Button>
                    </div>
                </div>
                </Container>
            </>
    )
}

export default KPChangePassword;