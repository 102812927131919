import React, { useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import Banner from '../Component/Banner';
import Button from 'react-bootstrap/Button';

import { authInFile } from './firebase';

import { signInWithEmailAndPassword } from 'firebase/auth';

import { useNavigate } from 'react-router-dom';

const KeikiParty = () => {
    const [email, setEmail] = useInput({ type: 'email'})
    const [password, setPassword] = useInput ({type: 'password'})
    const navigate = useNavigate()

    function useInput({ type }) {
        const [value, setValue] = useState("");
        const input = <input value={value} onChange={e => setValue(e.target.value)} type={type} />;
        return [value, input];
    }

    const login = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(authInFile, email, password) // this is how you use the method correctly. You gotta know how the method works!
            .then((userCredential) => {
                sessionStorage.setItem('userEmail',userCredential.user.email)
                navigate('/KeikiParty/HomePage', { state: {
                    userName: userCredential.user.displayName
                } 
                })
            })
            .catch((error) => {
                if (error.code === 'auth/wrong-password') {
                    alert('Wrong Password Bossing')
                }
                if (error.code === 'auth/invalid-email') {
                    alert('Invalid Email Bossing')
                }
                if (error.code === 'auth/user-not-found') {
                    alert('User not found bossing')
                }
                const errorMessage = error.message;
                console.log(errorMessage)
            });
    };

    return (
            <>
                <Container className="keiki-party">
                        <Banner caption="KEIKI PARTY"/>
                <Banner caption="LOGIN" />
                <div className='text-center'>
                    <div>
                        <Form onSubmit={login}>
                        <div>
                            <Form.Label>Email:</Form.Label>
                                {setEmail}
                        </div>
                        <div>
                            <Form.Label>Password:</Form.Label>
                            {setPassword}
                        </div>
                        <Button type="submit" value="Login" className="buttonReview" disabled= {false} variant="info">Login</Button>
                        </Form>
                    </div>
                </div>
                </Container>
            </>
    )
}

export default KeikiParty;