// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBczJbDQwtOizfXyzTNecVbzKc09Bzi4Pc",
  authDomain: "birthdays-e6a89.firebaseapp.com",
  databaseURL: "https://birthdays-e6a89-default-rtdb.firebaseio.com",
  projectId: "birthdays-e6a89",
  storageBucket: "birthdays-e6a89.appspot.com",
  messagingSenderId: "333419305366",
  appId: "1:333419305366:web:ead4d46cf3dc96dda525b7"
};

// Initialize Firebase
initializeApp(firebaseConfig);
export const db = getDatabase();
export const authInFile = getAuth();

    // // Set an initializing state whilst Firebase connects
    // const [initializing, setInitializing] = useState(true)
    // const [user, setUser] = useState('')

    // Handle user state changes
    // function onAuthStateChanged(user: any) {
    //     setUser(user)
    //     if (initializing) setInitializing(false);
    // }

    // useEffect(() => {
    //     const subscriber = auth().onAuthStateChanged(onAuthStateChanged)
    //     return subscriber
    // }, [])

    // if (initializing) return null
    
    // if (!user) {
    //     return (
    //         <View>
    //             <Text>Login</Text>
    //         </View>
    //     )
    // }