import React from 'react';
import { Container } from 'react-bootstrap';
import Banner from '../Component/Banner';
import RespoCanvas from '../Component/ResponsiveCanvas';
import NavBar from './NavBar';
import Footer from './Footer';

function checkLightMode() {
    if (sessionStorage.getItem('lightMode') === 'true')  {
      return true
    }
  }

class AnimePage extends React.Component {
    render () {
        return (
            <>
                <Container className="text-center">
                    <NavBar testProps= { checkLightMode() ? "light" : "dark" } className="navTest"/>
                        <Banner caption="Anime"/>
                        <p>Coming Soon!</p>
                        <RespoCanvas />
                </Container>
                <Footer testProps= { checkLightMode() ? "lightMode" : "darkMode" }/>
            </>
        )
    }
}

export default AnimePage;