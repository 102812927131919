import React from 'react';
import { Container } from 'react-bootstrap';
import Banner from '../Component/Banner';

const script = document.createElement("script");
script.src = './homeScript.js';


class ToDo extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight
        }

        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        document.body.appendChild(script);
        window.addEventListener('resize',this.handleResize);
    }

    componentWillUnmount() {
        document.body.removeChild(script);
        document.location.reload();
        window.removeEventListener('resize',this.handleResize)
    }

    handleResize() {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight
            })
    }

    render () {
        return (
            <Container className="text-center">
                    <Banner caption="To Do List"/>
                    <canvas id='javaCanvas' width={this.state.width/2} height={this.state.height/2}/>
                    <h1>Month of April</h1>
                    <h3>Navigation and Modal Presentation</h3>
                        <ul>
                            <li className='done'>Creating a Navigation Heirarchy</li>
                            <li className='done'>Managing Data Dlow Betwee Views</li>
                            <li className='done'>Creating the Edit View</li>
                        </ul>
                    <h3>Passing Data</h3>
                        <ul>
                            <li className='done'>Passing Data with Bindings</li>
                        </ul>
                    <h3>State Management</h3>
                    <ul>
                        <li className='undone'>Making Classes Observable</li>
                        <li className='undone'>Responding to Events</li>
                        <li className='undone'>Managing State and Life Cycle</li>
                        <li className='undone'>Updating App Data</li>
                    </ul> 

Persistence and Concurrency

    9: Persisting Data
    11: Adopting New API Features
    11: Adopting Swift Concurrency
    11: Modernizing Asynchronous Code
    12: Handling Errors 

Drawing

    13: Drawing the Timer View 

Recording Audio

    13: Examining Data Flow in Scrumdinger
    14: Transcribing Speech to Text
    14: Finding More Resources 

UIKit Essentials

    15: Getting Started with Today
    15: Creating a List View 

Collection Views and Navigation

    16: Adopting Collection Views
    16: Displaying Cell Info
    18: Making Reminders Identifiable
    19: Displaying Reminder Details 

Making Editable Views

    20: Getting Ready for Editing
    21: Managing Content Views
    22: Using Content Views
    23: Editing Reminders 

Adding, Deleting, and Filtering Reminders

    25: Adding and Deleting Reminders
    26: Filtering Reminders 

Custom Views and Animation

    27: Examining Accessibility in Today
    28: Creating a Progress View
    28: Creating a Gradient Background 

System Frameworks

    29: Loading Reminders
    30: Saving Reminders
    30: Continuing Your Learning
            </Container>
        )
    }
}


export default ToDo;