import React from 'react';

class Review extends React.Component {
    constructor(props){
        super(props);
        this.handleReview = this.handleReview.bind(this);
        this.handleChangeTheirReview = this.handleChangeTheirReview.bind(this);
        this.handleChangeReviewer = this.handleChangeReviewer.bind(this);
        this.addEmoji = this.addEmoji.bind(this);
        this.state = {
            reviews: [this.addEmoji("(Empty review"), 'Elmira L.', this.addEmoji("(Empty review"), 'Una K.', this.addEmoji('(Empty review'), 'Arlo A.'],
            reviewer: '',
            theirReview: ''
        }
    }

    addEmoji(text) {
        return <div>{text} &#128531;)</div>
    }

    handleReview() {
        this.setState({reviews: [...this.state.reviews, ...[this.state.theirReview,  this.state.reviewer] ] });
    }

    handleChangeTheirReview(e) {
        this.setState({theirReview: e.target.value})
    }
    
    handleChangeReviewer(e) {
        this.setState({reviewer: e.target.value})
    }

    render() {
        return (
            <div className="review">
                <div className="theirReview">
                    {formatReviews(this.state.reviews)}
                </div>
            </div>
        )
    }
}

function formatReviews(list) {
    const reviewList = list.map(function(review, index)
        {if (index % 2 === 0) {
            return (
                <li key={review.toString()}>
                {review}
                </li>
            )
        } else {
            return (
                <li key={review.toString()}>
                    -{review}
                </li>
            )
        }
    })
    if (list.length === 0) {
        return ( <p>Sorry! No reviews for now.</p>);
    }
    else {
        return (
            <div>
                <ul className="TheReview">
                    {reviewList}
                </ul>
            </div>
        )
    }
}

export default Review;