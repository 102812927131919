import React from "react";
import { Link } from "react-router-dom";

export default function CardLink(props) {
        return (
            <div className="col mb-4 cardStyle">
                        <div className={" card mb-3 " + props.bg }>
                        <img className={"card-img-top cardImg " + props.cardPic } alt=""/>
                            <div className="card-body">
                            <div className="card-title"><Link to={"/Games-" + props.cardButton} className="btn btn-primary">{props.cardButtonLink}</Link></div>
                                <p className="card-text">{props.cardDetails}<a href={props.cardToLink} target="_blank" rel="noopener noreferrer"><span className="cardLink"> {props.cardToLink}</span></a></p>
                            </div>
                        </div>
                    </div>
        )
    }