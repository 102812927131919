import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Banner from '../Component/Banner';

import NavBar from './../Content/NavBar';
import Footer from './../Content/Footer'

function checkLightMode() {
    if (sessionStorage.getItem('lightMode') === 'true')  {
      return true
    }
  }

class KeikiPartyInfo extends React.Component {


    render () {
        return (
            <>
                <Container className="text-center">
                    <NavBar testProps= { checkLightMode() ? "light" : "dark" } className="navTest"/>
                        <Banner caption="App Projects"/>
                        <div className='Apps'>
                        <h1>Open <a href='/KeikiParty'>Keiki Party Web Version</a></h1>
                        <hr />
                        <Row>
                            <Col>
                                <p>For iOS (iPhone) devices</p>
                                <img src='/kpios.png' className='KP-qrcode' alt='iOS QR Code'/>
                            </Col>
                            <Col>
                                <p>For Android devices</p>
                                <img src='/qr-code.png' className='KP-qrcode' alt='Android QR Code'/>
                            </Col>
                        </Row>
                        <hr />
                        <h1>Instructions:</h1>
                        <div className='KPInstructions'>
                                <ul className='KPList'>
                                <li>1. Click 'Create an Account' button</li>
                                    <ul>1.1. Type your name</ul>
                                    <ul>1.2. Your email should have @radinco.net domain.</ul>
                                    <ul><li>For example: player@radinco.net</li></ul>
                                    <ul>1.3. Type your password</ul>
                                    <ul>1.4. For the App Code (these are 1 time use only)</ul>
                                    <ul><li>FreeAppCode1</li></ul>
                                    <ul><li>FreeAppCode2</li></ul>
                                    <ul><li>FreeAppCode3</li></ul>
                                    <ul><li>Until FreeAppCode20</li></ul>
                                </ul>
                        </div>
                        <div className='KPInstructions'>
                                <ul className='KPList'>
                                <li>2. To access Caico's Birthday Party</li>
                                    <ul>2.1. Password is: Caico'sBig1</ul>
                                    <ul>2.2. Everything else, please see instructions</ul>
                                    
                                </ul>
                        </div>
                        <p>If you have any questions, let me know!</p>
                        <p>If you need an App Code, let me know, too!</p>
                        <h1>Mahalo!</h1>
                        </div>
                </Container>
                <Footer testProps= { checkLightMode() ? "lightMode" : "darkMode" }/>
            </>
        )
    }
}


export default KeikiPartyInfo;