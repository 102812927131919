import { Button, Container } from 'react-bootstrap';
import Banner from '../../Component/Banner';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get, ref, set } from 'firebase/database';

import { db } from '../../ReactNative/firebase';

const RRTrivia = () => {
    const [timer, setTimer] = useState(10)
    const [question, setQuestion] = useState(0)
    const navigate = useNavigate()
    const [chosen0, setChosen0] = useState('btnSizeUp')
    const [chosen1, setChosen1] = useState('btnSizeUp')
    const [chosen2, setChosen2] = useState('btnSizeUp')
    const [chosen3, setChosen3] = useState('btnSizeUp')
    const [userAnswers, setUserAnswers] = useState(
        [
            {
                id: 0,
                answer: ''
            },
            {
                id: 1,
                answer: ''
            },
            {
                id: 2,
                answer: ''
            },
            {
                id: 3,
                answer: ''
            },
            {
                id: 4,
                answer: ''
            },
            {
                id: 5,
                answer: ''
            },
            {
                id: 6,
                answer: ''
            },
            {
                id: 7,
                answer: ''
            },
            {
                id: 8,
                answer: ''
            },
            {
                id: 9,
                answer: ''
            },
            {
                id: 10,
                answer: ''
            },
            {
                id: 11,
                answer: ''
            },
            {
                id: 12,
                answer: ''
            },
            {
                id: 13,
                answer: ''
            },
        ]
    )

    const answerArray = useMemo(() => [
        '2 years',
        'Hawaii',
        'Park by the Capitol',
        'Going to the gym',
        'Seafood',
        '3-4',
        'A spot close to the Bountiful temple',
        'Orlando, Florida',
        'Sushi Buritto',
        'Utah',
        'Jerusalem',
        'Santorini, Greece',
        '3',
        '2',
    ], [])
    
    var questionArray = [
        'How long have they been together?',
        'Where did they first meet officially?',
        'Where did they go on their first date?',
        "The couple's favorite hobby to do together.",
        "Couple's favorite food to eat together.",
        "How many kids do they want to have?",
        "Where did they have their first kiss?",
        "Where are they going for their honeymoon?",
        "What's their favorite restaurant?",
        "Where will they live after getting married?",
        "Where is Rica's dream vacation?",
        "Where is Russel's dream vacation?",
        "How many siblings does Rica have?",
        "How many siblings does Russel have?",
    ]

    var choicesArray = [
        [
            '1 year',
            '2 years',
            '3 years',
            '0 year',
        ],
        [
            'Mission Home Philippines',
            'Salt Lake City, Utah',
            'Hawaii',
            'Disney Land'
        ],
        [
            'Laie Temple, Hawaii',
            'Mall of Asia, Philippines',
            'Mission Home',
            'Park by the Capitol'
        ],
        [
            'Eating',
            'Going to the gym',
            'Hiking',
            'Snorkeling'
        ],
        [
            'Pancit Canton',
            'Rice',
            'Veggie veggie veggie',
            'Seafood'
        ],
        [
            '1-2',
            '3-4',
            '4-5',
            'As the sand which is upon the sea'
        ],
        [
            'The Delicate Arch',
            'A spot close to the Laie temple',
            'A spot close to the Bountiful temple',
            'Foodland, Laie'
        ],
        [
            'Laoag, Philippines',
            'Orlando, Florida',
            'Los Angeles, California',
            'Tokyo, Japan'
        ],
        [
            'Ombu Korean BBQ',
            'Jollibee',
            'Sushi Buritto',
            'Whole Foods'
        ],
        [
            'Philippines',
            'Hawaii',
            'New York',
            'Utah'
        ],
        [
            'Austria',
            'Scotland',
            'Jerusalem',
            'Japan'
        ],
        [
            'Viti Levu, Fiji',
            'Malé Atoll, Maldives',
            'Santorini, Greece',
            'Machu Picchu, Peru'
        ],
        [
            '1',
            '2',
            '3',
            '4'
        ],
        [
            '1',
            '2',
            '3',
            '4'
        ],
    ]


    useEffect(() => {
        if (question === 0) {
            window.scrollTo(0, 0)
        }
        if (sessionStorage.getItem('TriviaScore') ){
            alert("Seems like you've already taken the quiz. Thanks for your participation!")
            navigate('/QuizTrivias/RusselRica/RRScoreboard')
        } else if (!sessionStorage.getItem('TriviaEmail')) {
            alert("Seems like you haven't added your info. Please do so first.")
            navigate('/QuizTrivias/RusselRica')
        }
        if (question === questionArray.length - 1  && timer === 0) {
            var userScore = 0
            for (var i = 0; i < answerArray.length; i++) {
                if(answerArray[i] === userAnswers[i]) {
                    userScore += 1
                }
            }
            sessionStorage.setItem('TriviaScore', userScore)

            get(ref(db, 'RRTriviaScoreboard/')).then((snap) => {
                const theNewValue = []
                for (var i = 0; i < snap.size; i++) {
                    theNewValue.push(snap.val()[i])
                }
                theNewValue.push({
                    email: sessionStorage.getItem('TriviaEmail'),
                    name: sessionStorage.getItem('TriviaName'),
                    score: userScore,
                })
                set(ref(db,'RRTriviaScoreboard/'), theNewValue)
            })

            navigate('/QuizTrivias/RusselRica/RREnd')
        } else {
            if (timer === 0) {
                setTimer(10)
                setQuestion((t) => t + 1)
                setChosen0("btnSizeUp")
                setChosen1("btnSizeUp")
                setChosen2("btnSizeUp")
                setChosen3("btnSizeUp")
            } else {
                const interval = setInterval(() => {
                    setTimer((t) => t - 1);
                }, 1000);
                return () => clearInterval(interval)
            }
        }
        const logAnswerArray = () => {
            console.log(answerArray)
        }
        logAnswerArray();
    }, [timer, navigate, question, questionArray.length, userAnswers, answerArray]) // gotta have the timer to manipulate useEffect

    function showChoices() {
        function answerChosen0() {
            // console.log(choicesArray[question][0])
            // userAnswers[question] = choicesArray[question][0]
            // setUserAnswers([
            //     ...userAnswers,
            //     {id: question, answer: choicesArray[question][0]}
            // ])
            const answer = userAnswers.map((a, i) => {
                if (i === question) {
                    return choicesArray[question][0]
                } else {
                    return a
                }
            })
            setUserAnswers(answer)
            setChosen0("btnSizeUp btn-success")
            setChosen1("btnSizeUp")
            setChosen2("btnSizeUp")
            setChosen3("btnSizeUp")
        }
        function answerChosen1() {
            // console.log(choicesArray[question][1])
            // userAnswers[question] = choicesArray[question][1]
            // console.log(userAnswers)
            const answer = userAnswers.map((a, i) => {
                if (i === question) {
                    return choicesArray[question][1]
                } else {
                    return a
                }
            })
            setUserAnswers(answer)
            setChosen0("btnSizeUp")
            setChosen1("btnSizeUp btn-success")
            setChosen2("btnSizeUp")
            setChosen3("btnSizeUp")
        }
        function answerChosen2() {
            // console.log(choicesArray[question][2])
            // userAnswers[question] = choicesArray[question][2]
            // console.log(userAnswers)
            const answer = userAnswers.map((a, i) => {
                if (i === question) {
                    return choicesArray[question][2]
                } else {
                    return a
                }
            })
            setUserAnswers(answer)
            setChosen0("btnSizeUp")
            setChosen1("btnSizeUp")
            setChosen2("btnSizeUp btn-success")
            setChosen3("btnSizeUp")
        }
        function answerChosen3() {
            // console.log(choicesArray[question][3])
            // userAnswers[question] = choicesArray[question][3]
            // console.log(userAnswers)
            const answer = userAnswers.map((a, i) => {
                if (i === question) {
                    return choicesArray[question][3]
                } else {
                    return a
                }
            })
            setUserAnswers(answer)
            setChosen0("btnSizeUp")
            setChosen1("btnSizeUp")
            setChosen2("btnSizeUp")
            setChosen3("btnSizeUp btn-success")
        }
        return(
            <div className="buttonToEdit">
                <br />
                <Button onClick={answerChosen0} className={chosen0}>{choicesArray[question][0]}</Button>
                <br />
                <br />
                <Button onClick={answerChosen1} className={chosen1}>{choicesArray[question][1]}</Button>
                <br />
                <br />
                <Button onClick={answerChosen2} className={chosen2}>{choicesArray[question][2]}</Button>
                <br />
                <br />
                <Button onClick={answerChosen3} className={chosen3}>{choicesArray[question][3]}</Button>
            </div>
        )
    }

    return (
        <>
            <Container className="keiki-party RRTriviaBackground">

                <Banner caption=""/>
            <div className='text-center'>
                <div className='Apps'>
                    <br />
                    <h1 className='Trivia-Timer'>{timer}</h1>
                    <br />
                    <h2 className='fontBackground'>{questionArray[question]}</h2>
                    {showChoices(question)}
                    
                </div>
            </div>
            <div className='row'>
                <div className='usedImageStyle'>
                    <a href="http://www.freepik.com">Designed by coolvector / Freepik</a>
                </div>
                    
                </div>
            </Container>
        </>
    )
}

export default RRTrivia;