import { Text, SafeAreaView, useColorScheme } from "react-native";

export default function HauulaBB() {
    const isDarkMode = useColorScheme() === 'dark';
    let height = window.screen.height
    return(
        <SafeAreaView style={{ flex: 1, backgroundColor: isDarkMode ? 'black' : 'white', height: height,justifyContent: 'center',
        alignItems: 'center'}}>
            <Text
                style= {{color: 'white', fontSize: 46,}}
            >
                Welcome to Hau'ula Bright Beginnings App!
            </Text>
        </SafeAreaView>
    )
}