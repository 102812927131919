import React from 'react';
import { Container } from 'react-bootstrap';
import Banner from '../Banner';

const script = document.createElement("script");
script.src = './phaser.js';

class Java2DGamePhaser extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    componentDidMount() {
        //document.head.appendChild(script1);
        
        document.body.appendChild(script);
    }
    componentWillUnmount() {
        document.body.removeChild(script);
        //document.head.removeChild(script1);
    }
    componentDidCatch(error, info) {
        this.setState({error: true})
    }

    static getDerivedStateFromError(error) {
        return {hasError: true}
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>
        }
        return (
            <Container>
                <Banner caption='JavaScript 2D Game (Phaser)' />
                {this.props.children}
                <h2 className='text-center'>Vue Suspended</h2>
            </Container>
        )
    }
    
}

export default Java2DGamePhaser;


