import React from 'react';
import { Container } from 'react-bootstrap';
import Banner from '../Component/Banner';
import { Row } from 'react-bootstrap';
import CardLink from '../Component/CardLink';
import NavBar from './NavBar';
import Footer from './Footer';

function checkLightMode() {
    if (sessionStorage.getItem('lightMode') === 'true')  {
      return true
    }
  }

export default function GamesPage(props) {
    return (
        <>
            <Container className="gamesPage">
            <NavBar testProps= { checkLightMode() ? "light" : "dark" } className="navTest"/>
                <Row className="justify-content-md-center">
                    <Banner caption="Games"/>
                </Row>
                <Row className="divider">
                    <CardLink
                        bg= {checkLightMode() ? "bg-light" : "bg-dark" }
                        cardPic="ticTacToePic"
                        cardButton="TicTacToe"
                        cardButtonLink="Tic Tac Toe"
                        cardDetails="Made using React Tutorial."
                        cardToLink="https://reactjs.org/tutorial/tutorial.html"
                    />
                    <CardLink
                        bg= {checkLightMode() ? "bg-light" : "bg-dark" }
                        cardPic="java2dgamePic"
                        cardButton="Java2DGame"
                        cardButtonLink="Java 2D Game"
                        cardDetails="From MDN Web Docs"
                        cardToLink="https://developer.mozilla.org/en-US/docs/Games/"
                    />
                    <CardLink
                        bg= {checkLightMode() ? "bg-light" : "bg-dark" }
                        cardPic="comingSoonPic"
                        cardButton="Java2DGamePhaser"
                        cardButtonLink="Java 2D Game (Phaser)"
                        cardDetails="From MDN Web Docs"
                        cardToLink="https://developer.mozilla.org/en-US/docs/Games/"
                    />
                </Row>
            </Container>
            <Footer testProps= { checkLightMode() ? "lightMode" : "darkMode" }/>
        </>
        )
    }