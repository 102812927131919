import { useEffect, useState } from 'react';
import { db } from '../../ReactNative/firebase';
import { useNavigate } from 'react-router-dom';
import { Text, Platform, SafeAreaView, View, ScrollView, FlatList, useColorScheme } from 'react-native'
import { onValue, ref } from 'firebase/database';

const YScoreboard = () => {
    const isDarkMode = useColorScheme() === 'dark';
    const navigate = useNavigate()
    let height = window.screen.height
    const [dataHere, setData] = useState<
        Array<{
            name: String,
            score: number,
            time: String
        }>
        >([])

    useEffect(() => {
        showScoreboard()
    }, [navigate])

    function showScoreboard() {
        // updates date when there are changes
        onValue(ref(db, 'YTriviaScoreboard/'),(snap => {
            const scoreboard = snap.val()
            scoreboard.sort((a: { score: number; },b: { score: number; }) => b.score - a.score)
            setData(scoreboard)
        }))
    }

    return (
        <>
        {/* <Container className="keiki-party"> */}
                <div className='keiki-party'>
                <SafeAreaView style={{ flex: 1, backgroundColor: isDarkMode ? 'black' : 'white', height: height}}>
                <View // HEADER
                    style= {{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Text
                        style= {{
                            color: isDarkMode ? 'white' : 'black',
                            fontSize: 46,
                            fontFamily: Platform.OS === 'android' ? 'serif' : 'Noteworthy-bold',
                        }}
                    >
                        Scoreboard
                    </Text>
                </View>
                <View //BODY
                    style= {{
                        flex: 8,
                        borderColor: 'powderblue',
                        borderWidth: 2,
                        borderRadius: 20,
                        margin: 10,
                        alignItems: 'center',
                    }}
                >
                    <View
                        style= {{
                            flexDirection: 'row',
                        }}
                    >
                        <View
                            style= {{
                                // flex: 5,
                            }}
                        >
                            <Text
                                style= {{
                                    // borderWidth: 1,
                                    // borderColor: 'orange',
                                    fontSize: 30,
                                    padding: 5,
                                    color: isDarkMode ? 'white' : 'black',
                                    width: 200,
                                    fontFamily: Platform.OS === 'android' ? 'serif' : 'Noteworthy',
                                    paddingLeft: 10,
                                    textAlign: 'center',
                                }}
                            >
                                Name
                            </Text>
                        </View>
                        <View>
                            <Text
                                style= {{
                                    // borderWidth: 1,
                                    // borderColor: 'red',
                                    fontSize: 30,
                                    padding: 5,
                                    textAlign: 'center',
                                    color: isDarkMode ? 'white' : 'black',
                                    width: 80,
                                    fontFamily: Platform.OS === 'android' ? 'serif' : 'Noteworthy',
                                }}
                            >
                                Score
                            </Text>
                        </View>
                    </View>
                    <ScrollView
                        nestedScrollEnabled= {true}
                        horizontal= {true}
                        style= {{
                            flex: 1,
                            // alignSelf: 'stretch',
                            // borderRadius: 5,
                        }}
                    >
                    <FlatList
                        style= {{
                            // margin: 10,
                            borderColor: 'orange',
                        }}
                        data={dataHere}
                        horizontal= {false}
                        renderItem={({item}) =>
                            <View
                                style= {{
                                    flexDirection: 'row',
                                }}
                            >
                                <View>
                                    <Text 
                                        style= {{
                                            fontSize: 22,
                                            padding: 5,
                                            color: isDarkMode ? 'white' : 'black',
                                            paddingLeft: 10,
                                            textAlign: 'center',
                                            width: 200,
                                            // borderWidth: 1,
                                            // borderColor: 'blue',
                                        }}>{item.name} 
                                    </Text>
                                </View>
                                <View
                                    style= {{
                                    }}
                                >
                                    <Text 
                                        style= {{
                                            //color: isDarkMode ? 'white' : 'black',
                                            fontSize: 22,
                                            padding: 5,
                                            width: 80,
                                            textAlign: 'center',
                                            color: isDarkMode ? 'white' : 'black',
                                        }}>{item.score}
                                    </Text>
                                </View>
                            </View>
                        }
                    />
                    </ScrollView>
                </View>
        </SafeAreaView>
        </div>
        {/* </Container> */}
        </>
    );
}

export default YScoreboard;