import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { NavLink } from "react-router-dom";


class NavBar extends React.Component {
    render() {
        return (
            <>
                <Container>
                    <Navbar bg = {this.props.testProps} variant = { this.props.testProps }>
                        <Navbar.Brand href="/">
                            <img alt="ps buttons"
                                src="../icon.jpg"
                                width="30"
                                height="30"
                                className="d-inline-block align-top"/> Home
                        </Navbar.Brand>
                        <Nav className="me-auto">
                            <NavLink className="nav-link" to="/Games">Games</NavLink>
                            <NavLink className="nav-link" to="/Anime">Anime</NavLink>
                            <NavLink className="nav-link" to="/Apps">Apps</NavLink>
                            
                        </Nav>   
                    </Navbar>
                </Container>
            </>
      )
    }
}

export default NavBar;