import React from 'react';
import { Container, Row } from 'react-bootstrap';

const Footer = (props) => {
    return (
        <Container fluid>
            <div className= "footer">
                <div className={props.testProps}>
                    <Row className="justify-content-md-center">
                        <div>
                            <a href="https://www.github.com/caicohr" target="_blank" rel="noopener noreferrer"><button className="w3-button w3-large w3-circle w3-black iconButton"><i className="fa fa-github"></i></button></a>
                            <a href="https://www.facebook.com/undidecimo" target="_blank" rel="noopener noreferrer"><button className="w3-button w3-large w3-circle w3-black iconButton"><i className="fa fa-facebook-square"/> </button></a>
                            <a href="https://www.linkedin.com/in/jericoradin" target="_blank" rel="noopener noreferrer"><button className="w3-button w3-large w3-circle w3-black iconButton"><i className="fa fa-linkedin"></i></button></a>
                            <a href="https://www.instagram.com/kokaubeam_29/" target="_blank" rel="noopener noreferrer"><button className="w3-button w3-large w3-circle w3-black iconButton"><i className="fa fa-instagram"></i></button></a>
                        </div>
                    </Row>
                    <Row className="justify-content-md-center">
                        <div>
                            © 2023 Jerico Radin
                        </div>
                    </Row>
                </div>
            </div>
        </Container>
    )
}

export default Footer;