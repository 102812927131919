import { Button, Container, Form } from 'react-bootstrap';
import { useState } from 'react';

import { db } from '../../ReactNative/firebase';
import { child, get, ref } from 'firebase/database';
import { useNavigate } from 'react-router-dom';

const YHome = () => {
    const [name, setName] = useInput({type: 'text'})
    const [email, setEmail] = useInput({ type: 'email'})
    const navigate = useNavigate()

    function useInput({ type }) {
        const [value, setValue] = useState("");
        const input = <input value={value} onChange={e => setValue(e.target.value)} type={type} />
        return [value, input];
    }

    const startQuiz = (e) => {
        e.preventDefault();
        // if (sessionStorage.getItem('TriviaEmail')) {
        //     alert("Seems like you've taken the quiz already. If you want to take it again, please see celebrants!")
        // }
        // if (sessionStorage.getItem('TriviaName')) {
        //     alert("Seems like you've taken the quiz already. If you want to take it again, please see celebrants!")
        // }
        if (!name) {
            alert("Please type your name first.")
        } else if(!email) {
            alert("Please type your email, too.")
        } else {
            const dbRef = ref(db)
            get(child(dbRef,'YTriviaScoreboard/')).then(snap => {
                var foundEmail = false
                for (var i=0; i< snap.size; i++) {
                    if (email.toLowerCase() === snap.val()[i].email.toLowerCase()) {
                        // console.log('Found email')
                        alert("Seems like you've taken the quiz already with this email. If you want to take it again, please see celebrants!")
                        foundEmail = true
                    }
                }
                if (!foundEmail) {
                    sessionStorage.setItem('TriviaEmail',email)
                    sessionStorage.setItem('TriviaName',name)
                    navigate('/QuizTrivias/Yoonseo/YTrivia')
                }
            })

        }
    }

    return (
            <>
                <Container className="keiki-party YTriviaBackground">
                        <h1 className='ycustom-banner'>Welcome to Yoonseo's Trivia!</h1>
                <div className='text-center'>
                    <div className='Apps'>
                    <Form onSubmit={startQuiz}>
                    <h3>Before taking it, please fill out info:</h3>
                    <Form.Label className='fontBackground'>Name:</Form.Label>
                        {setName}
                    <br />
                    <br />
                    <Form.Label className='fontBackground'>Email:</Form.Label>
                        {setEmail}
                    <br />
                    <br />
                        <Button type="submit" value="startQuiz" className="ybtn-info" disabled= {false} variant="info">Start Trivia</Button>
                    </Form>
                    </div>
                </div>
                
                </Container>
            </>
    )
}

export default YHome;