import React from 'react';
import { Container } from 'react-bootstrap';
import Banner from '../Banner';

const script = document.createElement("script");
script.src = './script.js';

class Java2DGame extends React.Component {
    componentDidMount() {
        document.body.appendChild(script);
    }
    componentWillUnmount() {
        document.body.removeChild(script);
        document.location.reload();
    }

    render() {
        return (
            <Container>
                <Banner caption='JavaScript 2D Game' />
                <canvas id='myCanvas'>
                </canvas>
            </Container>
        )
    }
}

export default Java2DGame;


