import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Row,Col } from 'react-bootstrap';

export const SendReview = () => {
    const form = useRef();

    const sendReview = (e) => {
        e.preventDefault();
        disableButton();
        emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_USERID_KEY)
        .then((result) => {
            alert("Review Sent! Thank you!", result.text);
        }, (error) => {
            alert("An error has occured. Please try again.", error.text);
        });
    };

    function disableButton() {
        const button = document.querySelector('button')
         button.disabled = true;
    }
  
    return (
        <div className="reviewCreator">
        <hr />
            <h3>You can send a review, too!</h3>
            <p>Let me know what things to improve on as well!</p>
            <Form ref={form} onSubmit={sendReview} >
                <Form.Label>Review:</Form.Label><br />
                <textarea name="message" className="reviewBox mb-3" required/><br />
                <Form.Label>Name:</Form.Label>
                <input type="text" name="reviewer_name" className="mb-3" required/><br />
                <Form.Label className="mb-3">Relationship:</Form.Label>
                <Form.Select name="reviewer_rel" id="reviewer_rel" aria-label="Default select example">
                    <option>Co-worker</option>
                    <option>Friend</option>
                    <option>Boss</option>
                    <option>Supervisor</option>
                    <option>Manager</option>
                    <option>Professor</option>
                    <option>Family</option>
                    <option>Stranger</option>
                    <option>Recruiter</option>
                    <option>Enemy</option>
                </Form.Select><br />
                <Row className="justify-content-md-center">
                    <Col className=" col-3">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email adress:</Form.Label>
                        <Form.Control type="email" name="reviewer_email" placeholder="Enter email"/>
                        <Form.Text className="text-muted">If you'd like to receive a reply, you can include your email address!</Form.Text>
                    </Form.Group>
                    </Col>
                </Row>
                    <Button type="submit" value="Send Review" className="buttonReview" disabled= {false} variant="info">Send Review </Button><br />
            </Form>
        </div>
    );
  };