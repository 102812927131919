import React from 'react';

//Component
//import Banner from './Component/Banner';

//Content
// import NavBar from './Content/NavBar';
import HomePage from './Content/HomePage';
import GamesPage from './Content/GamesPage';
import Anime from './Content/Anime';
import TicTacToe from './Component/Games/TicTacToe';
import Java2DGame from './Component/Games/Java2DGame';
import Java2DGamePhaser from './Component/Games/Java2DGamePhaser';
import ToDo from './Content/ToDo';
import Apps from './Content/Apps';
import KeikiParty from './ReactNative/KeikiParty';
import KPHomePage from './ReactNative/KPHomePage';
import KPScoreboard from './ReactNative/KPScoreboard';
import KPChangePassword from './ReactNative/KPChangePassword';
import KeikiPartyInfo from './ReactNative/KeikiPartyInfo';
import HauulaBB from './HauulaBrightBeginnings/HauulaBB';
import QuizTrivias from './Quiz-Trivias/QuizTrivias';
import RRHome from './Quiz-Trivias/RusselRica/RRHome';
import RRTrivia from './Quiz-Trivias/RusselRica/RRTrivia';
import RREnd from './Quiz-Trivias/RusselRica/RREnd';
import RRScoreboard from './Quiz-Trivias/RusselRica/RRScoreboard';
import YHome from './Quiz-Trivias/Yoonseo/YHome';
import YTrivia from './Quiz-Trivias/Yoonseo/YTrivia';
import YEnd from './Quiz-Trivias/Yoonseo/YEnd';
import YScoreboard from './Quiz-Trivias/Yoonseo/YScoreboard';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//Stylesheets
import './Stylesheet/App.css';
// import './Stylesheet/NavBar.css';
import './Stylesheet/NavigationBar.css';
import './Stylesheet/HomePage.css';
//import './Stylesheet/Donations.css';
import './Stylesheet/GamesPage.css';
import './Stylesheet/Footer.css';
import './Stylesheet/TicTacToe.css';
import './Stylesheet/Java2D.css';
import './Stylesheet/ToDo.css';
import './Stylesheet/homeCanvas.css'
import './Stylesheet/KeikiParty.css'


function toNightMode() {
  sessionStorage.setItem('lightMode','true');
}

function toLightMode() {
  sessionStorage.setItem('lightMode','false');
}

function checkLightMode() {
  if (sessionStorage.getItem('lightMode') === 'true')  {
    return true
  }
}


class App extends React.Component {
  constructor(props) {
    super(props);
    this.handleNightMode = this.handleNightMode.bind(this);
    this.state = {
        reload: false
   }
  }

  handleNightMode() {
    this.setState(
      { reload: true }
    )
    if(checkLightMode() === true) {
      toLightMode();
    } else {
      toNightMode();
    }
  }

  render() {
    return (
      <>
        <div className= {checkLightMode() ? "lightMode" : "darkMode" }>
          <div className="content">
            <label className="switch">
              <input type="checkbox" onClick={this.handleNightMode}/>
              <span className="slider round"></span>
              <br /><span className={ checkLightMode() ? "lightText" : "nightText" }>{ checkLightMode() ? "Night Mode" : "Light Mode" }</span>
            </label>
            <Router>
                <Routes>
                    {/* <Route exact path= "/">
                        <HomePage />
                    </Route> */}
                    <Route exact path="/" element={<HomePage/>} />
                    {/* <Route path= "/Games">
                        <GamesPage bg= "light" textColor="darkText"/>
                    </Route> */}
                    <Route path="/Games" element={<GamesPage bg= "light" textColor="darkText"/>} />
                    {/* <Route path= "/Anime">
                        <Anime />
                    </Route> */}
                    <Route path="/Anime" element={<Anime/>} />
                    {/* <Route path="/ToDo">
                        <ToDo />
                    </Route> */}
                    <Route path="/ToDo" element={<ToDo/>} />
                    {/* <Route path= "/Games-TicTacToe">
                        <TicTacToe />
                    </Route> */}
                    <Route path="/Games-TicTacToe" element={<TicTacToe/>} />
                    {/* <Route path= "/Games-Java2DGame">
                        <Java2DGame />
                    </Route> */}
                    <Route path="/Games-Java2DGame" element={<Java2DGame/>} />
                    {/* <Route path="/Games-Java2DGamePhaser">
                        <Java2DGamePhaser />
                    </Route> */}
                    <Route path="/Games-Java2DGamePhaser" element={<Java2DGamePhaser/>} />
                    {/* <Route path="/Apps">
                        <Apps />
                    </Route> */}
                    <Route path="/Apps" element={<Apps/>} />
                    {/* <Route path="/KeikiParty">
                        <KeikiParty />
                    </Route> */}
                    <Route path="/KeikiParty" element={<KeikiParty/>} />
                    {/* <Route path="/KPHomePage">
                        <KPHomePage />
                    </Route> */}
                    <Route path="/KeikiParty/HomePage" element={<KPHomePage/>} />
                    <Route path="/KeikiParty/Scoreboard" element={<KPScoreboard/>} />
                    <Route path="/KeikiParty/ChangePassword" element={<KPChangePassword/>} />
                    <Route path='/KeikiPartyInfo' element={<KeikiPartyInfo/>} />
                    <Route path='/HauulaBB' element={<HauulaBB/>} />
                    <Route path='/QuizTrivias' element={<QuizTrivias/>} />
                    <Route path='/QuizTrivias/RusselRica' element={<RRHome/>} />
                    <Route path='/QuizTrivias/RusselRica/RRTrivia' element={<RRTrivia/>} />
                    <Route path='/QuizTrivias/RusselRica/RREnd' element={<RREnd/>} />
                    <Route path='/QuizTrivias/RusselRica/RRScoreboard' element={<RRScoreboard/>} />

                    <Route path='/QuizTrivias/Yoonseo' element={<YHome/>} />
                    <Route path='/QuizTrivias/Yoonseo/YTrivia' element={<YTrivia/>} />
                    <Route path='/QuizTrivias/Yoonseo/YEnd' element={<YEnd/>} />
                    <Route path='/QuizTrivias/Yoonseo/YScoreboard' element={<YScoreboard/>} />
                </Routes>
            </Router>
          </div>
        </div>
      </>
    )
  }
}
export default App;