import { useEffect, useState } from 'react';

import { Button, Container } from 'react-bootstrap';

import Banner from '../Component/Banner';
import { onAuthStateChanged } from 'firebase/auth';
import { authInFile } from './firebase';
import { useNavigate, useLocation } from 'react-router-dom';

const KPHomePage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [banner, setBanner] = useState('')

    useEffect(() => {
        onAuthStateChanged(authInFile, (user) => {
            if (!user) {
                alert("You've been logged out. Please log back in.")
                navigate('/KeikiParty')
            } else {
                setBanner('Aloha, ' + location.state.userName + "!")
            }
        })
    })

    function checkSession() {
        navigate('/KeikiParty/Scoreboard')
    }

    return (
        <>
        <Container className="keiki-party">
            <div className='kp-hpbanner'>
                <Banner caption={banner}/>
            </div>
                <div className='text-center'>
                    <Button onClick={checkSession}>Go Scoreboard</Button>
                </div>
        </Container>
        </>
    );
}

export default KPHomePage;